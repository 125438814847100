import $ from "jquery";
import moment from 'moment'

moment.locale('fr');
// ISO-8601, Europe
moment.updateLocale('fr', {
    week: {
        dow: 1, // First day of week is Monday
        doy: 4  // First week of year must contain 4 January (7 + 1 - 4)
    }
});

class SteppedDatePicker {

    constructor() {
        this.init();
        this.make('2000', '2018');
    }

    init() {
        $.fn.steppedDatePicker = function (options) {
            let container = $('<div style="display:none;" id="stepped-datepicker" class="stepped-picker"></div>'),
                self = this,
                opt = $.extend({}, $.fn.steppedDatePicker.defOptions, options),
                save = function (key, val) {
                    $.data(container, key, val);
                },
                get = function (key) {
                    return $.data(container, key);
                },
                hideOnBlur = function () {
                    $(self).on('blur', function () {
                        $(container).hide();
                    });
                },

                fillContainer = function (title, elClass, from, to, clickHandler) {
                    hideOnBlur();

                    container.empty();
                    container.append('<div class="title">' + title + '</div>');

                    for (let i = from; i <= to; i++) {
                        const el = $('<div class="' + elClass + '">' + i + '</div>');

                        container.append(el);

                        // prevent blur closing popup when selecting date parts
                        $(el).on('mousedown', function () {
                            $(self).off('blur');
                        });

                        $(el).on('click tap', function (evt) {
                            // re-enable blur handling and select bound element
                            hideOnBlur();
                            // $(self).click();

                            // call the customizable click handler
                            clickHandler.call(this);
                        });
                    }
                },

                fillContainerMonth = function (title, elClass, from, to, clickHandler) {
                    hideOnBlur();

                    container.empty();
                    container.append('<div class="title">' + title + '</div>');

                    let monthlist = moment.months();

                    monthlist.forEach(function (elm, index) {
                        let el = $('<div class="' + elClass + '" data-month-id="' + (index + 1) + '">' + elm + '</div>');

                        container.append(el);

                        // prevent blur closing popup when selecting date parts
                        $(el).on('mousedown', function () {
                            $(self).off('blur');
                        });

                        $(el).on('click tap', function (evt) {
                            // re-enable blur handling and select bound element
                            hideOnBlur();
                            // $(self).click();

                            // call the customizable click handler
                            clickHandler.call(this);
                        });
                    });
                },

                showDays = function () {
                    const daysInMonth = new Date(get('year'), get('month'), 0).getDate();

                    fillContainer(
                        opt.dayTitle, 'day', 1, daysInMonth,
                        function () {
                            $(this).addClass('selected');
                            save('day', $(this).text());
                            container.hide();
                            $(opt.input).val(opt.dateHandler(new Date(get('year'), get('month') - 1, get('day'))));
                            $(opt.input).trigger('change');
                        }
                    );
                },

                showMonths = function () {
                    fillContainerMonth(
                        opt.monthTitle, 'month', 0, 11,
                        function () {
                            $(this).addClass('selected');
                            save('month', $(this).attr('data-month-id'));
                            showDays();
                        }
                    );
                };

            container.insertAfter(this);

            fillContainer(
                opt.yearTitle, 'year', opt.yearStart, opt.yearEnd,
                function () {
                    $(this).addClass('selected');
                    $.data(container, 'year', $(this).text());
                    showMonths();
                }
            );

            $(container).show().css({
                'left': $(opt.input).position().left,
                'top': $(opt.input).position().top + $(self).innerHeight()
            });
        };

        $.fn.steppedDatePicker.defOptions = {
            yearStart: 2001, yearEnd: 2014,
            yearTitle: 'Choisir une année', monthTitle: 'Choisir le mois', dayTitle: 'Choisir le jour',
            dateHandler: function (d) {
                return d;
            }
        };
    }

    pad(num, length) {
        let numText = num.toString();
        return numText.length >= length
            ? numText
            : new Array(length - numText.length + 1).join('0') + numText;
    }

    make(yearStart, yearEnd) {
        let _this = this;
        $('.stepped-date-button').on('click tap', function (e) {
            e.preventDefault();
            $('.stepped-picker').remove();
            $(this).steppedDatePicker({
                yearStart: yearStart, yearEnd: yearEnd,
                input: '.stepped-date-imp1',
                dateHandler: function (d) {
                    let date = d.getFullYear() + '' + ('0' + (d.getMonth() + 1)).slice(-2) + '' + ('0' + d.getDate()).slice(-2);
                    let d1 = moment(date, 'YYYYMMDD');
                    return d1.format("DD/MM/YYYY");
                }
            });
        });

        // $('#stepped-date-imp2').steppedDatePicker({
        //   yearStart: yearStart, yearEnd: yearEnd,
        //   yearTitle: 'Velg år', monthTitle: 'Velg måned', dayTitle: 'Velg dag',
        //   dateHandler: function (d) {
        //     return _this.pad(d.getDate(), 2) + '.' + _this.pad(d.getMonth() + 1, 2) + '.' + d.getFullYear();
        //   }
        // });
    }
}

export default SteppedDatePicker